import store from "../../store";
import router from "../../router";
export default {
  Q: (item) => {
    let type = typeof item;
  },
  picQ(item) {
    console.log(item);
    if (Array.isArray(item) && item.length != 0) {
      let arr = [];
      item.forEach((element) => {
        arr.push({
          id: "",
          uploadname: element.name,
          description: "",
          storagename: element.response,
        });
      });
      return arr;
    } else {
      return item;
    }
  },
  picQ2(item) {
    // console.log(item)
    if (Array.isArray(item) && item.length != 0) {
      let arr = [];
      item.forEach((element) => {
        arr.push(element.response);
      });
      return arr;
    } else {
      return item;
    }
  },
  QPic(item, apiUrl) {
    let list = [];
    if (item != undefined) {
      item.forEach((element) => {
        list.push({
          status: "done",
          url: apiUrl + element.storagename,
          response: element.storagename,
          name: element.uploadname,
        });
      });
    }
    return list;
  },
  QPic2(item, apiUrl) {
    let list = [];
    if (item != undefined) {
      item.forEach((element, i) => {
        list.push({
          uid: i,
          status: "done",
          url: apiUrl + element,
          response: element,
        });
      });
    }
    return list;
  },
  dateQ(item) {
    if (typeof item == "object") {
      return item.format("YYYY-MM-DD");
    } else {
      return item;
    }
  },
  timeQ(item) {
    if (typeof item == "object") {
      return item.format("HH:mm");
    } else {
      return item;
    }
  },
  arrToStr(arr) {
    let str = "";
    arr.forEach((item, i) => {
      if (i) {
        str += item + "、";
      } else {
        str += item;
      }
    });
    return str;
  },
  dateRangeQ(item) {
    if (item.length > 0) {
      if (item[0] && item[1]) {
        let arr = [];
        item.forEach(function (element) {
          arr.push(element.format("YYYY-MM-DD"));
        });
        return arr;
      } else {
        return ["", ""];
      }
    } else {
      return ["", ""];
    }
  },
  idName(id, list) {
    if (id) {
      return list.find((item) => item.id == id).name;
    } else {
      return "";
    }
  },
  idNameList(idList, list) {
    let arr = [];
    if (idList.length > 0) {
      idList.forEach((element) => {
        arr.push(list.find((item) => item.id == element).name);
      });
      return arr;
    } else {
      return [];
    }
  },
  nameId(name, list) {
    if (name) {
      return list.find((item) => item.name == name).id;
    } else {
      return "";
    }
  },
  nameIdList(nameList, list) {
    let arr = [];
    if (nameList.length > 0) {
      nameList.forEach((element) => {
        arr.push(list.find((item) => item.name == element).id);
      });
      return arr;
    } else {
      return [];
    }
  },
  //data:列表的数据 column:需要加上筛选的列名，columns列表的表头
  filter: (column, data, columns) => {
    column.forEach((item) => {
      let arr = [];
      if (data) {
        data.forEach((element) => {
          if (element[item] || element[item] === 0) {
            arr.push(element[item]);
          }
        });
      }
      let filters = [];
      if (arr) {
        let obj = new Set(arr);
        // console.log(new Set(arr))
        for (const key of obj) {
          // console.log(key)
          filters.push({
            text: key,
            value: key,
          });
        }
        // console.log(filters)
        columns.forEach((element) => {
          if (element.key == item) {
            element.filters = filters;
            element.onFilter = (value, record) =>
              record[item].indexOf(value) === 0;
          }
        });
        // console.log(columns)
      }
    });
  },
  filterName(input, option) {
    return (
      option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >=
      0
    );
  },
  filterNameInitial(input, option) {
    let name = option.children[0].children;
    let nameInitial = store.state.medicineList.find(
      (item) => item.name == name
    ).nameInitial;
    console.log(option);
    // console.log(name);
    // console.log(nameInitial);
    // let obj = {
    //   name,
    //   nameInitial: store.state.medicineList.find(item => item.name == name),
    // };
    // console.log(obj);
    return (
      name?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
      nameInitial?.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  },
  reset(object) {
    if (object.constructor === Object) {
      for (let key in object) {
        if (object[key].constructor === Object) {
          this.reset(object[key]);
        } else if (object[key].constructor === Array) {
          object[key] = [];
        } else if (object[key].constructor === String) {
          object[key] = "";
        } else if (object[key].constructor === Number) {
          object[key] = undefined;
        }
      }
    } else if (object.constructor === Array) {
      object = [];
    } else if (object.constructor === String) {
      object = "";
    } else if (object.constructor === Number) {
      object = undefined;
    }
    return object;
  },
  reset2(obj) {},
  deepClone(obj) {
    // 写法1
    // let newObj = obj.constructor === Array ? [] : {}
    // if(typeof obj !== 'object') {
    // 	return obj
    // }else{
    // 	for(var i in obj) {
    // 		if(typeof obj[i] === 'object'){
    // 			newObj[i] = deepClone(obj[i])
    // 		}else{
    // 			newObj[i] = obj[i]
    // 		}
    // 	}
    // }
    // return newObj;

    // 写法2
    if (!obj && typeof obj !== "object") {
      throw new Error("error arguments", "deepClone");
    }
    const targetObj = obj.constructor === Array ? [] : {};
    Object.keys(obj).forEach((keys) => {
      if (obj[keys] && typeof obj[keys] === "object") {
        targetObj[keys] = deepClone(obj[keys]);
      } else {
        targetObj[keys] = obj[keys];
      }
    });
    return targetObj;
  },
  changeTab(option) {
    //判断是否已开启标签
    let isOpen = false;
    store.state.pages.forEach((element, i) => {
      // 点击对应pages(标签栏)第几项
      if (option.name == element.name) {
        isOpen = true;
      }
    });

    if (option.delete != undefined) {
      store.state.pages.forEach((element, i) => {
        if (element.name == option.delete) {
          store.state.pages.splice(i, 1);
        }
      });
    }

    router.options.routes.forEach((element, i) => {
      if (element.name == option.name) {
        // 没有此标签则添加
        if (!isOpen) {
          store.state.pages.push({
            name: element.name,
            link: element.path,
          });
        }

        router.push(element.path);
        // console.log("查看成分：" + router.options.routes[i]?.meta?.keepAlive)
        if (router.options.routes[i]?.meta?.keepAlive != undefined) {
          router.options.routes[i].meta.keepAlive = true;
        }

        //启动过快导致keepAlive修改无效，先跳转空白页，刷新一次页面
        // setTimeout(() => {
        //   router.push("/blank");
        // }, 10);

        router.push(element.path);

        store.state.pages.forEach((element, i) => {
          if (option.name == element.name) {
            store.state.activeKey = i;
          }
        });

        // alert(router.options.routes[i].meta.keepAlive)
      }
    });
  },
  hasDuplicateIds(array) {
    const idMap = new Map();

    for (const item of array) {
      if (idMap.has(item.id)) {
        // 如果 id 已经存在于 map 中，说明有重复,显示重复的id
        console.log(item.id);
        return true;
      }
      idMap.set(item.id, item);
    }
    // 如果循环结束都没有发现重复的 id，返回 false
    return false;
  },
  // console.log(hasDuplicateIds(targetArray));
};
