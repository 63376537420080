import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sdcom/employee/list", { params });
}

export function Update(params) {
  return request.put("sdcom/employee", params);
}

export function Delete(data) {
  return request.delete("sdcom/employee", { data });
}
