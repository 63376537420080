<template>
  <div>
    <a-modal v-model:visible="$store.state.selectClub.visible" :title="title" @ok="handleOk" okText="确认" cancelText="取消" :destroyOnClose="$store.state.selectClub.control" :keyboard="limite" :closable="$store.state.selectClub.control" :maskClosable="$store.state.selectClub.control" :cancelButtonProps="cancelButtonProps">
      <a-radio-group v-model:value="uuid">
        <a-radio v-for="item in $store.state.clubs" :key="item.id" size="large" :style="style" :value="item.uuid">{{item.name}}</a-radio>
      </a-radio-group>
    </a-modal>
  </div>
</template>

<script>
import { changeClub } from "/src/api/selectClub.js";
import { getOption } from "/src/api/login.js";
export default {
  data() {
    return {
      title: "请选择门诊门店",
      uuid: "",
      cancelButtonProps: { style: { display: 'none' } },
      style: {
        "display": 'block',
        "height": '50px',
        "lineHeight": '50px',
        'font-size': '20px',
        "vertical-align": "middle",
      }
    };
  },
  created() {

  },
  mounted() {
    if (this.$store.state.clubs) {
      this.uuid = this.$store.state.clubs[0].uuid

    }
    this.setButton()
  },
  activated() {
    // this.uuid = this.$store.state.club.uuid
  },
  methods: {
    async handleOk() {
      let res = await changeClub({ uuid: this.uuid })
      if (res.errCode == 0) {
        this.$store.state.club = this.$store.state.clubs.find(item => item.uuid == this.uuid)
        let res2 = await getOption()
        this.$store.state.links = res2.data

        this.$message.success("切换到当前门店为：" + this.$store.state.club.name);
        this.$store.state.selectClub.visible = false
        this.$store.state.selectClub.control = true

        // 切换致导航栏首个
        if (res2.data.length > 0) {

          this.$store.state.pages = [
            {
              name: this.$store.state.links[0].subMenus[0].name,
              link: this.$store.state.links[0].subMenus[0].component,
            },
          ];
          this.$store.state.openKeys = this.$store.state.links[0].name;
          this.$store.state.selectedKeys = [this.$store.state.links[0].subMenus[0].name];
          this.$store.state.activeKey = 0;
          this.$store.commit("changePage", {
            name: this.$store.state.links[0].subMenus[0].name,
          });
        } else {
          this.$store.state.pages = [];
          this.$store.commit("changePage", {
            name: ""
          });
        }


      }
      this.setButton()
      this.$store.dispatch("getEmployeeList");
      // this.visible = false;
    },
    setButton() {
      if (this.$store.state.club?.id) {
        this.cancelButtonProps = {}
      } else {
        this.cancelButtonProps = { style: { display: 'none' } }
      }
    }
  }
};
</script>

<style scoped>
:deep(.ant-radio + span) {
  vertical-align: middle;
}
:deep(.ant-radio-checked + span) {
  /* color: red; */
}
</style>
