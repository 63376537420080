import { request } from "/src/utils/index.js";

export function login(userInfo) {
  return request.post("auth/employee/login", userInfo);
}
export function logout() {
  return request.post("auth/employee/logout");
}

export function getOption() {
  return request.get("sys/menu/my");
}

export function UserInfo() {
  return request.get("auth/employee/current");
}
