<template>
  <div>
    <a-layout>
      <a-layout>
        <a-layout-sider width="200" style="background: #fff">
          <a-menu :selectedKeys="$store.state.selectedKeys" :openKeys="$store.state.openKeys" mode="inline" :style="{ height: '100%', borderRight: 0 }" class="menu">
            <div class="logo" @click="t">
              <img src="../../assets/imgs/logo.jpg" alt />
            </div>
            <hr />
            <div>

              <!-- <a-sub-menu key="日常管理统计">
                <template #title>
                  <span>日常管理统计</span>
                </template>
                <a-menu-item @click="changeTab('员工管理')" key="员工管理">员工管理</a-menu-item>
                <a-menu-item @click="changeTab('门诊日报')" key="门诊日报">门诊日报</a-menu-item>
              </a-sub-menu> -->

              <a-sub-menu v-for="item in $store.state.links" :key="item.name">
                <template #title>
                  <span>{{item.name}}</span>
                </template>
                <a-menu-item v-for="item2 in item.subMenus" @click="changeTab(item2.name)" :key="item2.name">{{item2.name}}</a-menu-item>
              </a-sub-menu>
            </div>

          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 0px 10px 24px">
          <a-layout-content :style="{background: '#fff',padding: '24px',margin: 0,minHeight: '280px'}">
            <div class="flex_sb">
              <div class="titleStyle3">后台管理系统</div>
              <!-- <a-popover class="popover" title="用户xxx，您好"> -->
              <div class="userInfo"><span>{{$store.state.employee.name}}({{$store.state.club?.name}})</span>，您好</div>
              <a-popover class="popover" trigger="click">
                <template #content>
                  <!-- <p @click="showEdit">修改密码</p> -->
                  <p v-if="$store.state?.clubs?.length > 1" @click="changeClub">切换门店</p>
                  <p @click="logout">退出登录</p>
                </template>
                <a-button class="Info_btn" type="primary"></a-button>
                <!-- <div class="userInfo">
                  用户
                  <span>xxx</span>，您好
                </div> -->
              </a-popover>
            </div>

            <a-tabs :activeKey="$store.state.activeKey" type="editable-card" @edit="onEdit" hideAdd @tabClick="showThis($event)">
              <a-tab-pane v-for="(item, i) in $store.state.pages" :key="i">
                <template #tab>{{ item.name }}</template>
                <!-- <keep-alive>
                <router-view>

                </router-view>
              </keep-alive>-->
              </a-tab-pane>
            </a-tabs>
            <!-- <router-view v-if="$route?.meta?.keepAlive" v-slot="{ Component }"> -->

            <router-view v-if="
              $route?.meta?.limite == '只读' || $route?.meta?.limite == '可编辑'
            " v-slot="{ Component }">
              <!-- <keep-alive :include="['项目管理','房源管理','租客管理','房源管理','房源管理','房源管理']"> -->
              <transition name="fade" appear>
                <keep-alive>
                  <Component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
            <!-- <router-view v-else></router-view> -->
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <a-modal width="600px" v-model:visible="visible" title="修改密码" @ok="editPsw" okText="确认" cancelText="取消">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
        <a-form-item label="原密码">
          <a-input-password v-model:value="edit.oldpassword" />
        </a-form-item>
        <a-form-item label="新密码">
          <a-input-password v-model:value="edit.newpassword" loading />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input-password v-model:value="edit.newpassword2" />
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>
<script>
import {
  UserOutlined,
  LaptopOutlined,
  NotificationOutlined,
  HomeOutlined,
  TeamOutlined,
  DesktopOutlined,
  BarChartOutlined,
  ContactsOutlined,
  AuditOutlined,
  ScheduleOutlined,
  ToolOutlined,
  FundProjectionScreenOutlined,
  UserSwitchOutlined,
  TransactionOutlined
} from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { logout } from "/src/api/login.js";
export default defineComponent({
  components: {
    UserOutlined,
    LaptopOutlined,
    NotificationOutlined,
    HomeOutlined,
    TeamOutlined,
    DesktopOutlined,
    BarChartOutlined,
    ContactsOutlined,
    AuditOutlined,
    ScheduleOutlined,
    ToolOutlined,
    FundProjectionScreenOutlined,
    UserSwitchOutlined,
    TransactionOutlined
  },
  name: "home",
  data() {
    return {
      routes: [],


    };
  },
  setup() {
    return {
    };
  },
  methods: {
    onEdit: function (targetKey) {
      let name = this.$store.state.pages[targetKey].name;
      console.log("targetKey:" + targetKey);
      //判断删除是否激活的标签
      let deleteIsActiveTarget = targetKey == this.$store.state.activeKey;

      //剩余标签数
      let countTrue = 0;
      countTrue = this.$store.state.pages.length;
      // 删除当前激活标签时 跳转目标标签的下标
      let afterDeleteActiveIndex;
      // console.log("targetKey:" + targetKey)
      // console.log(countTrue - 1)

      //标签大于1 可以删除
      if (countTrue > 1) {
        // 删除第0个
        if (targetKey == 0) {
          afterDeleteActiveIndex = 0;
        } else {
          // 删除选中项后面的
          if (targetKey > this.$store.state.activeKey) {
            afterDeleteActiveIndex = this.$store.state.activeKey;
          }
          // 删除选中项/选中项前面的
          else if (targetKey <= this.$store.state.activeKey) {
            afterDeleteActiveIndex = this.$store.state.activeKey - 1;
          }
        }

        // console.log("afterDeleteActiveIndex：" + afterDeleteActiveIndex)
        this.$store.state.pages.splice(targetKey, 1);

        if (deleteIsActiveTarget) {
          this.$router.push(this.$store.state.pages[afterDeleteActiveIndex].link);
        }
        this.$store.state.activeKey = afterDeleteActiveIndex;
        this.$router.options.routes.forEach((element, i) => {
          if (name == element.name) {
            if (this.$router.options.routes[i]?.meta?.keepAlive != undefined) {
              this.$router.options.routes[i].meta.keepAlive = false;
            }
          }
        });
      }


      // this.$router.options.routes[3].meta.keepAlive = false

      console.log(this.$store.state.pages[afterDeleteActiveIndex].name)
      this.$store.commit("changeKey", this.$store.state.pages[afterDeleteActiveIndex].name)
      console.log("afterDeleteActiveIndex:" + afterDeleteActiveIndex);
      console.log("activeKey:" + this.$store.state.activeKey);
    },
    //左侧标签跳转
    changeTab: function (tabName) {
      //判断是否已开启标签
      let isOpen = false;
      this.$store.state.pages.forEach((element, i) => {
        // 点击对应pages(标签栏)第几项
        if (tabName == element.name) {
          isOpen = true;
        }
      });

      this.$router.options.routes.forEach((element, i) => {
        if (element.name == tabName) {
          // 没有此标签则添加
          if (!isOpen) {
            this.$store.state.pages.push({
              name: element.name,
              link: element.path
            });
          }

          //跳转至点击项的标签
          this.$router.push(element.path);
          // console.log("查看成分：" + this.$router.options.routes[i]?.meta?.keepAlive)
          if (this.$router.options.routes[i]?.meta?.keepAlive != undefined) {
            this.$router.options.routes[i].meta.keepAlive = true;
          }

          // 启动过快导致keepAlive修改无效，先跳转空白页，刷新一次页面
          setTimeout(() => {
            this.$router.push("/blank");
          }, 10);

          setTimeout(() => {
            this.$router.replace(element.path);
          }, 20);
          // console.log(this.$store.state.pages)
          this.$store.state.pages.forEach((element, i) => {
            if (tabName == element.name) {
              this.$store.state.activeKey = i;
            }
          });
          // alert(this.$router.options.routes[i].meta.keepAlive)
        }
      });
      this.$store.commit("changeKey", tabName)
    },
    // 点击切换上部导航栏
    showThis(tab) {
      // alert(tab)
      this.$store.state.activeKey = tab;
      // console.log(tab)
      // console.log(this.$store.state.pages[tab])
      this.$router.push(this.$store.state.pages[tab].link);
      this.$store.commit("changeKey", this.$store.state.pages[tab].name)

      // this.$router.push("/")
    },
    t() {
      console.log(this.$store.state);
    },
    async logout() {
      let res = await logout()
      if (res.errCode == 0) {
        this.$store.state.pageState = 'login'
        localStorage.setItem("token", "");
        this.$router.push("/")
      } else {
        this.$message.error(res.errMsg)
      }
    },
    getAPI(apiNameList) {
      // apiNameList.forEach(element => {
      //   this.$store.commit("getApi", { name: element, api: this.$fwapi });
      // });
    },
    showEdit() {
      this.visible = true;
    },
    changeClub() {
      this.$store.state.selectClub.visible = true
      this.$store.state.selectClub.control = true
    }

  },
  mounted() {
    this.getAPI([]);
  },
  watch: {
    $route(to, from) {
      // console.log(from.path);//从哪来
      // console.log(to.path);//到哪去
      // this.getRoute();
    }
  }
});
</script>
<style>
#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout {
  height: 100vh;
}
.ant-breadcrumb {
  margin: 16px 0 !important;
}

.flex_sb {
  position: relative;
}
.userInfo {
  position: absolute;
  font-size: 1rem;
  font-weight: bold;
  right: 70px;
  top: 27%;
  cursor: pointer;
}
.popover {
  cursor: pointer;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* .fade-enter-from {
  transform: translateX(-30px);
} */
/* 
.fade-leave-to {
  transform: translateX(30px);
} */
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
/* .fade-leave-active, */
.fade-enter-active {
  transition: all 1s;
  /* transition: opacity 0.5s linear 0s; */
  /* position: absolute; */
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  -webkit-animation-name: none;
  animation-name: none;
}
.fade-leave.fade-leave-active {
  -webkit-animation-name: none;
  animation-name: none;
}
.menu {
  overflow: auto;
  overflow-x: hidden;
  /* width: 12vw !important; */
}
</style>