import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sys/permission/list", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("sys/permission" + params);
}

export function Add(params) {
  return request.post("sys/permission", params);
}

export function Update(params) {
  return request.put("sys/permission", params);
}

export function Delete(data) {
  return request.delete("sys/permission", { data });
}

