import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("tcm/syndrome/list", { params });
}

export function Add(params) {
  return request.post("tcm/syndrome", params);
}

export function Update(params) {
  return request.put("tcm/syndrome", params);
}

export function AddRelation(params) {
  return request.put("tcm/syndrome", params);
}

export function Delete(data) {
  return request.delete("tcm/syndrome", { data });
}
