import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("tcm/prescription/list", { params });
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("tcm/prescription/" + params);
}

export function Add(params) {
  return request.post("tcm/prescription/", params);
}

export function Update(params) {
  return request.put("tcm/prescription/", params);
}

export function Delete(data) {
  return request.delete("tcm/prescription/", { data });
}

export function AddMedicine(params) {
  return request.post("tcm/prescription/item", params);
}

export function UpdateMedicine(params) {
  return request.put("tcm/prescription/item", params);
}

export function DeleteMedicine(data) {
  return request.delete("tcm/prescription/item", { data });
}