<!--  -->
<template>
  <login v-if="$store.state.pageState=='login'" />
  <home v-if="$store.state.pageState=='home'" />
  <selectClubs v-if="$store.state.pageState=='home'" />
  <div class="loading" :class="$store.state.loading ? 'showLoading' : 'hideLoading'"></div>
</template>

<script>
import home from "./views/home/home.vue";
import login from "./views/login/login.vue";
import selectClubs from '/src/components/selector/selectClub.vue';
export default {
  components: {
    home,
    login,
    selectClubs
  },
  data() {
    return {
    };
  },
  methods: {
    logined() {
      this.$store.commit("getUserInfo", { type: "auto" })
    }
  },
  mounted() {
    this.logined()
    // this.resetApi()
    // this.$router.push("/");
    // if(true){
    //   this.$store.state.pageState=='home'
    // }
  },
};
</script>

<style scoped>
</style>
