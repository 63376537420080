import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { request, setupAntd } from "/src/utils/index.js";
import "./assets/css/index.css";
import moment from "moment";
import { message } from "ant-design-vue";
import axios from "axios";
// import global from "./assets/js/global.js"
import "./mock/index";
import x from "./assets/js/xMethods.js";
import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

// import mitt from "mitt"
moment.locale("zh-cn");
// document.title = '测试'
const app = createApp(App);
setupAntd(app);
// app.use(Button).use(Layout)
app.use(store).use(router).mount("#app");

//不显示警告
app.config.warnHandler = () => null;

app.config.globalProperties.$x = x;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$request = request;
app.config.globalProperties.$message = message;

app.config.globalProperties.$debugMode = true;
app.config.globalProperties.$testMode = true;

// app.config.globalProperties.$api = "http://10.168.8.156:8084/NewlionsoftRealty/"
// app.config.globalProperties.$api = "http://test.newlionsoft.com:20080/newlionsoftrealty/"

// if (app.config.globalProperties.$testMode) {
//     app.config.globalProperties.$api = "http://test.newlionsoft.com:20080/newlionsoftrealty/"
// } else {
//     app.config.globalProperties.$api = "http://183.194.251.233:7005/HouseMaterialPlatformAPI/"
// }
if (window.location.href.indexOf("https://962121.fgj.sh.gov.cn/") != -1) {
  app.config.globalProperties.$fileApi =
    "https://962121.fgj.sh.gov.cn/qyrealty/";
} else if (
  window.location.href.indexOf("localhost") != -1 ||
  window.location.href.indexOf(
    "http://test.newlionsoft.com:20080/NewlionsoftFXBaoZuFang"
  ) != -1
) {
  app.config.globalProperties.$fileApi =
    "http://test.newlionsoft.com:20080/newlionsoftrealty/";
  app.config.globalProperties.$fwapi = "/FxbzfFwApi";
  app.config.globalProperties.$fwapi2 = "/newFxbzfFwApi";
  app.config.globalProperties.$mgcapi = "/FxbzfMgcApi";
  app.config.globalProperties.$mgcapi2 = "/newFxbzfMgcApi";
}

app.use(Datetime);
