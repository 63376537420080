import { request } from "/src/utils/index.js";

export function Search(params) {
  return request.get("tcm/medicine/list", { params });
}
export function Detail(params) {
  return request.get("tcm/medicine/" + params + "?includeAlias=true");
}

export function Add(params) {
  return request.post("tcm/medicine", params);
}

export function Update(params) {
  return request.put("tcm/medicine", params);
}

export function Delete(data) {
  return request.delete("tcm/medicine", { data });
}
