
const Mock = require('mockjs')

Mock.mock('/api/member', {
    "id|+1": "10000001",
    'name': '@name', // 随机生成姓名
    'age|1-100': 100, // 随机生成1-100的数字
    'color': '@color', // 随机生成颜色
})

// 启动服务
Mock.mock('http://example.com/api/data', {
    'data|1-10': [{
        'id|+1': 10001,
        'name': '@name',
        'phone': /^1[3456789]\d{9}$/,
        'pos': Mock.Random.pick(['医生', '医生助理', '药房', '财务助理', '治疗师']), // 从预定义的职位数组中随机选择一个
        'part': Mock.Random.pick(['门诊', '数据中心', '门诊部', '']), // 从预定义的部门数组中随机选择一个
    }]
})